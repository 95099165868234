import * as process from 'node:process';
import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

const APP_MODES = ['ems', 'usmle'] as const;
export type AppMode = (typeof APP_MODES)[number];

const VERCEL =
  process.env?.VERCEL ||
  process.env?.VITE_VERCEL ||
  import.meta.env?.VERCEL ||
  import.meta.env?.VITE_VERCEL;
const VERCEL_URL =
  process.env?.VERCEL_URL ||
  process.env?.VITE_VERCEL_URL ||
  import.meta.env?.VERCEL_URL ||
  import.meta.env?.VITE_VERCEL_URL;

export const env = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_APP_NAME: z.string(),
    VITE_API_URL: z.string().url(),
    VITE_AI_API_URL: z.string().url(),
    VITE_APP_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set VITE_API_URL
      // It will automatically use the VERCEL_URL if present.
      (str) => VERCEL_URL ?? str,
      // VERCEL_URL doesn't include `https` so it cant be validated as a URL
      VERCEL ? z.string() : z.string().url(),
    ),
    VITE_APP: z.enum(APP_MODES),
    VITE_SENTRY_DSN: z.string().url(),
    VITE_SENTRY_PROJECT: z.string(),
    VITE_SENTRY_ORG: z.string(),
    VITE_SENTRY_ENV: z.enum(['production', 'development']),
    VITE_GTM_ID: z.string(),
    VITE_HOTJAR_ID: z.string(),
    VITE_CLARITY_ID: z.string(),
  },
  runtimeEnv: import.meta.env,
  emptyStringAsUndefined: true,
});
